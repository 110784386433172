<template>
  <div style="height: 100%">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top flex-space-between">
          <el-page-header @back="goBack" content="详情"> </el-page-header>
          <div>
            <!-- <el-button type="primary" size="small" @click="goBack" plain>取消</el-button>
            <el-button type="primary" size="small" @click="saveData">保存</el-button> -->
          </div>
        </div>
        <div class="table-line"></div>
        <div class="table-content">
          <el-form ref="form" :model="form" label-width="100px" :rules="rules" style="width: 70%">
            <el-form-item label="处方名称:">
              <el-input v-model="form.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="输液处方:">
              <div class="my-table">
                <el-table :data="form.drug" style="width: 100%">
                  <el-table-column type="index" label="编号" width="80" />
                  <el-table-column prop="name" label="中药材名称" width="260">
                    <el-input v-model="form.name" disabled></el-input>
                  </el-table-column>
                  <el-table-column prop="name" label="用量">
                    <el-input v-model="form.name" disabled></el-input>
                  </el-table-column>
                  <el-table-column prop="name" label="炮制方法">
                    <el-input v-model="form.name" disabled></el-input>
                  </el-table-column>
                </el-table>
                <el-row>
                  <el-col :span="7">
                    <el-form-item label="剂数">
                      <el-input v-model="form.name" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7" :offset="1">
                    <el-form-item label="每日剂量">
                      <el-input v-model="form.name" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7" :offset="1">
                    <el-form-item label="用药频率">
                      <el-input v-model="form.name" disabled></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="用法" style="width: 96%;">
                  <el-input v-model="form.name" disabled></el-input>
                </el-form-item>
                <el-form-item label="服务要求" style="width: 96%;">
                  <el-input type="textarea" v-model="form.name" disabled></el-input>
                </el-form-item>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      tableData: [],
      addData: {},
      form: {
        name: '',
        type: [],
        drug: [
          {
            edit: true,
            name: '1',
            guidancePrice: null,
            membershipPrice: null,
            ordinaryPrice: null,
            stockNum: null,
            rank: null,
          },
        ],
      },
    }
  },
  mounted () { },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>
<style scoped lang="scss">

.iq-card-body {
  padding: 0 0 15px;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {
    padding-left: 20px;

    .title {
      background: #f4f5f7;
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      margin-bottom: 20px;
    }

    ::v-deep .el-table__row>td {
      /* 去除表格线 */
      border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    ::v-deep .el-table .el-table__cell {
      // padding: 0 !important;
    }

    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }
    .el-form-item .el-form-item{
      margin-bottom: 15px;
    }
  }
}
</style>
